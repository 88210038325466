/* Fondo principal con degradado radial */
.background-container {
    position: relative;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    background: radial-gradient(circle at left, #b0b3b8, #4f566b);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    animation: ripple 15s infinite;
    box-shadow: 0px 0px 1px 0px rgba(80, 143, 185, 0.5);
  }
  
  /* Tamaños de círculos para pantallas grandes */
  .small {
    width: 200px;
    height: 200px;
    left: -100px;
    bottom: -100px;
  }
  
  .medium {
    width: 400px;
    height: 400px;
    left: -200px;
    bottom: -200px;
  }
  
  .large {
    width: 600px;
    height: 600px;
    left: -300px;
    bottom: -300px;
  }
  
  .xlarge {
    width: 800px;
    height: 800px;
    left: -400px;
    bottom: -400px;
  }
  
  .xxlarge {
    width: 1000px;
    height: 1000px;
    left: -500px;
    bottom: -500px;
  }
  
  /* Opacidad de los círculos */
  .shade1 { opacity: 0.2; }
  .shade2 { opacity: 0.4; }
  .shade3 { opacity: 0.6; }
  .shade4 { opacity: 0.8; }
  .shade5 { opacity: 1; }
  
  @keyframes ripple {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(0.8);
    }
  }
  
  .context h1 {
    color: #0f0f0f;
    font-size: 50px;
    position: relative;
    z-index: 1;
  }
  
  /* Estilos para pantallas medianas (tablets) */
  @media (max-width: 768px) {
    .small {
      width: 150px;
      height: 150px;
      left: -75px;
      bottom: -75px;
    }
  
    .medium {
      width: 300px;
      height: 300px;
      left: -150px;
      bottom: -150px;
    }
  
    .large {
      width: 450px;
      height: 450px;
      left: -225px;
      bottom: -225px;
    }
  
    .xlarge {
      width: 600px;
      height: 600px;
      left: -300px;
      bottom: -300px;
    }
  
    .xxlarge {
      width: 750px;
      height: 750px;
      left: -375px;
      bottom: -375px;
    }
  
    .context h1 {
      font-size: 40px;
    }
  }
  
  /* Estilos para pantallas pequeñas (móviles) */
  @media (max-width: 480px) {
    .small {
      width: 100px;
      height: 100px;
      left: -50px;
      bottom: -50px;
    }
  
    .medium {
      width: 200px;
      height: 200px;
      left: -100px;
      bottom: -100px;
    }
  
    .large {
      width: 300px;
      height: 300px;
      left: -150px;
      bottom: -150px;
    }
  
    .xlarge {
      width: 400px;
      height: 400px;
      left: -200px;
      bottom: -200px;
    }
  
    .xxlarge {
      width: 500px;
      height: 500px;
      left: -250px;
      bottom: -250px;
    }
  
    .context h1 {
      font-size: 30px;
    }
  }
  