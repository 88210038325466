/* Estilo del contenedor de partículas */
#particles-js {
    position: fixed;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #1c1c1c, #800000, #000000, #b71c1c); /* Gradiente con color vino */
    background-size: 400% 400%; /* Aumentamos el tamaño del gradiente para un movimiento más dinámico */
    z-index: -1; 
    top: 0;
    left: 0;
    opacity: 1; /* No hay transparencia para que el fondo sea completamente visible */
    animation: gradientMovement 10s ease-in-out infinite; /* Animación del movimiento del gradiente más rápida */
}

/* Animación del gradiente en movimiento */
@keyframes gradientMovement {
    0% {
        background-position: 0% 0%;
    }
    25% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    75% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

/* Contenido principal */
.content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    opacity: 0;
    animation: fadeIn 2s forwards, bounce 1s 2s ease-in-out forwards;
}

/* Animación de desvanecimiento */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Animación de rebote */
@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(0);
    }
    70% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

.content h1 {
    font-size: 5rem;
    margin-bottom: 1rem;
    opacity: 0;
    animation: fadeInText 2s forwards 0.5s;
}

@keyframes fadeInText {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.content p {
    font-size: 1.5rem;
    opacity: 0;
    animation: fadeInText 2s forwards 1s;
}

/* Estilo del contenedor blanco para ejemplo este lo puedes quitar*/
.bottom-container {
    background-color: white;
    padding: 50px 0;
    text-align: center;
    height: 100px; 

    
}
/* Tnhomepage.css */
.bottom-container ul,
.bottom-container ol {
  list-style: none; /* Elimina los puntos */
  margin: 0; /* Elimina el margen por defecto */
  padding: 0; /* Elimina el relleno por defecto */
}

/* Pie de página al final aqui este lo vas a sustutuir por el diseño que te pase*/
.footer {
    background-color: #333333;
    color: white;
    text-align: center;
    padding: 20px;
    position: relative; 
    bottom: 0;
    width: 100%;
}



/* Tnhomepage.css */
.no-bullets {
    list-style: none; /* Quita los puntos */
    margin: 0;
    padding: 0;
  }